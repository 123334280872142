<template>
  <a-modal title="详情" :width="800" :visible="visible" :destroyOnClose="true" @cancel="handleCancel" :footer="null">
    <a-spin :spinning="confirmLoading">
      <a-row>
        <a-form layout="inline">
          <a-col :md="12" :sm="24">
            <a-form-item label="订单号">
              <span>{{ details.orderNo }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="用户昵称">
              <span>{{ details.customerName }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="手机号">
              <span>{{ details.mobile }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="商品价（元）">
              <span>{{ details.goodsPrice }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="邮费（元）">
              <span>{{ details.postage }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="供应商">
              <span>{{ details.storeName }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="供应商手机号">
              <span>{{ details.storeMobile }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="下单时间">
              <span>{{ details.createTime }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="到账时间">
              <span>{{ details.arrivalTime }}</span>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      details: {},
      form: this.$form.createForm(this),
    }
  },
  methods: {
    //初始化方法
    detail(record) {
      this.visible = true
      this.confirmLoading = true
      //获取详情数据
      setTimeout(() => {
        this.details = record
        this.confirmLoading = false
      }, 100)
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.details = {} //关闭之后清空
    },
  },
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;

  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }

  td {
    border: 2px solid rgb(228, 225, 225);
    padding: 7px;
    font-size: 15px;
    width: 30%;
  }
}

img {
  height: 100px;
}
</style>
